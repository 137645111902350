import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import flatMap from 'lodash-es/flatMap';
import forEach from 'lodash-es/forEach';
import keyBy from 'lodash-es/keyBy';
import mapValues from 'lodash-es/mapValues';
import some from 'lodash-es/some';

import { DepartmentModel } from '../../../../reducers/orm/department/department.model';
import { EmployeeModel, UsersGroup } from '../../../../reducers/orm/employee/employee.model';
import { DepartmentOptionsPerLocation } from '../../../../reducers/orm/location/location.model';
import { PermissionGroupModel } from '../../../../reducers/orm/permission-group/permission-group.model';
import { TeamModel } from '../../../../reducers/orm/team/team.model';
import { mapEntities } from '../../../../reducers/shared/entity.helper';

@Injectable()
export class EmployeePermissionFormService {
  public createForm(
    form: UntypedFormGroup,
    departmentOptionsPerLocation: DepartmentOptionsPerLocation[],
    groups: PermissionGroupModel[],
    defaultDisable = false,
  ) {
    const departments = keyBy(
      flatMap(departmentOptionsPerLocation, (location) => location.departments),
      'id',
    );

    const departmentControls = mapValues(departments, (department) =>
      this.createDepartmentControl(department, groups, defaultDisable),
    );

    // @Todo: set validation select at least 1 team
    const departmentForm = new UntypedFormGroup(departmentControls, validateHasSelectedTeam);

    form.addControl('Department', departmentForm);
  }

  public locationGroupForm(
    departmentOptionsPerLocation: DepartmentOptionsPerLocation[],
    groups: PermissionGroupModel[],
  ) {
    const locations = keyBy(departmentOptionsPerLocation, (location) => location.location.id);

    const locationControls = mapValues(locations, () => new UntypedFormGroup(this.createGroupsToggleControl(groups)));

    return new UntypedFormGroup(locationControls);
  }

  private createDepartmentControl(department: DepartmentModel, groups: PermissionGroupModel[], defaultDisable = false) {
    const groupControls = this.createGroupsToggleControl(groups);

    const controls = new UntypedFormGroup(
      {
        id: new UntypedFormControl(department.id, [Validators.required]),
        team_id: new UntypedFormControl(''),
        UserGroups: new UntypedFormControl([]),
        UsersGroup: new UntypedFormGroup(groupControls),
      },
      validateSelectedTeamShouldHavePermissions,
    );

    if (defaultDisable) {
      controls.disable({ emitEvent: false });
    }

    return controls;
  }

  private createGroupsToggleControl(groups: PermissionGroupModel[]): { [groupId: string]: UntypedFormControl } {
    return mapValues(keyBy(groups, 'id'), () => new UntypedFormControl(false));
  }

  public setEmployeeData(
    form: UntypedFormGroup,
    employee: EmployeeModel,
    teamsPerDepartment: { [departmentId: string]: TeamModel[] },
  ) {
    if (!employee) {
      return;
    }

    const departmentForm = form.get('Department') as UntypedFormGroup;

    //teamIds to departmentId
    const teams = keyBy(
      flatMap(teamsPerDepartment, (department) => department),
      'id',
    );
    const selectedTeams = employee.Team ? mapEntities(employee.Team, teams) : [];

    this.resetToDefault(departmentForm);

    if (!employee.id) {
      return;
    }

    form.get('user_id').setValue(employee.id, { emitEvent: false });

    this.setTeams(departmentForm, selectedTeams);
    this.setGroups(departmentForm, employee.UsersGroup);

    //reset form status
    form.reset(form.value);
  }

  private resetToDefault(form: UntypedFormGroup) {
    forEach(form.controls, (departmentControl: UntypedFormGroup) => {
      // unset teams
      departmentControl.get('team_id').setValue('', { emitEvent: false });

      const GroupsControl = departmentControl.get('UsersGroup') as UntypedFormGroup;

      // unset groups
      forEach(GroupsControl.controls, (GroupControl: UntypedFormControl) => {
        GroupControl.setValue(false, { emitEvent: false });
      });
    });
  }

  private setTeams(form: UntypedFormGroup, selectedTeams: TeamModel[]) {
    //select selectedTeams
    selectedTeams.forEach((selectedTeam) => {
      const teamControl = form.get([selectedTeam.department_id, 'team_id']);

      if (!teamControl) {
        return;
      }

      teamControl.setValue(selectedTeam.id, {
        emitEvent: false,
      });
    });
  }

  private setGroups(form: UntypedFormGroup, selectedGroups: UsersGroup[]) {
    selectedGroups.forEach((selectedGroup) => {
      const usersGroupControl = form.get([selectedGroup.department_id, 'UsersGroup', selectedGroup.group_id]);

      if (!usersGroupControl) {
        return;
      }

      const groupsCtrl = form.get([selectedGroup.department_id, 'UserGroups']);
      const groupValue = <string[]>groupsCtrl?.value ?? [];
      groupValue.push(selectedGroup.group_id);
      groupsCtrl.setValue(groupValue);

      if (groupValue.length > 0) {
        form.get(selectedGroup.department_id).enable({ emitEvent: false });
      }
      usersGroupControl.setValue(true, {
        emitEvent: false,
      });
    });
  }
}

function validateSelectedTeamShouldHavePermissions(departmentForm: UntypedFormGroup): ValidationErrors {
  // include disabled fields in check
  const departmentValues = departmentForm.getRawValue();

  if (!departmentValues.team_id) {
    return null;
  }

  const hasAPermission =
    some(departmentValues.UsersGroup, (selected) => selected) || departmentValues.UserGroups?.length > 0;

  if (hasAPermission) {
    return null;
  }

  return {
    missingPermission: true,
  };
}

function validateHasSelectedTeam(departmentsForm: UntypedFormGroup): ValidationErrors {
  const values = departmentsForm.value;

  const hasATeam = some(values, (department: any) => !!department.team_id);

  if (hasATeam) {
    return null;
  }

  return {
    missingTeam: true,
  };
}
